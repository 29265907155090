const middleware = {}

middleware['recaptcha'] = require('../middleware/recaptcha.js')
middleware['recaptcha'] = middleware['recaptcha'].default || middleware['recaptcha']

middleware['search-console'] = require('../middleware/search-console.ts')
middleware['search-console'] = middleware['search-console'].default || middleware['search-console']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.ts')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

export default middleware
