import { ref } from '@nuxtjs/composition-api';
import { useFetchServiceCenters } from '@webplatform/nuxt-cms-js-sdk';

interface Address {
  name: string;
  address: string;
  phones: string[];
  lat: number;
  lng: number;
  countryId: number;
  cityId: number;
}
export default () => {
  const { fetchServiceCenters, centers } = useFetchServiceCenters({
    limit: 1000,
  });
  const serviceCenters = ref<Address[]>([]);

  const doFetchServiceCenters = async () => {
    await fetchServiceCenters();
    centers.value.forEach((s: any) => {
      s.addresses.forEach((address: any) => {
        serviceCenters.value.push({
          name: s.name,
          address: address.address,
          phones: address.phones as string[],
          lat: Number(address.lat),
          lng: Number(address.lon),
          countryId: address.country_id,
          cityId: address.city_id,
        });
      });
    });
  };

  return {
    centers,
    serviceCenters,
    fetchServiceCenters: doFetchServiceCenters,
  };
};
