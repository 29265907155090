import { Context } from '@nuxt/types';

export default ({ req, store }: Context) => {
  const GSVDomains = ['canyon.eu'];
  const currentDomain = req?.headers?.host;
  if (currentDomain !== undefined && GSVDomains.includes(currentDomain)) {
    store.commit('config/setGSVMetas', {
      name: 'google-site-verification',
      content: '5w9uWxPTWi8mAlOiZ_ugHepEpMgycMXoR43_YzyFYt4',
    });
  }
};
