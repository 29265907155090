var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"cn-input"},[(_vm.label)?_c('span',{staticClass:"cn-input__label",class:{ 'cn-input__label--disabled': _vm.disabled }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.icon)?_c('UiIcon',{staticClass:"cn-input__icon",attrs:{"name":_vm.icon}}):_vm._e(),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"cn-input__input",class:[
      { 'cn-input__input--with-icon': _vm.icon },
      _vm.error && 'cn-input__input--error',
    ],attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.valueLocal)?_vm._i(_setup.valueLocal,null)>-1:(_setup.valueLocal)},on:{"change":function($event){var $$a=_setup.valueLocal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.valueLocal=$$a.concat([$$v]))}else{$$i>-1&&(_setup.valueLocal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.valueLocal=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"cn-input__input",class:[
      { 'cn-input__input--with-icon': _vm.icon },
      _vm.error && 'cn-input__input--error',
    ],attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_setup.valueLocal,null)},on:{"change":function($event){_setup.valueLocal=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"cn-input__input",class:[
      { 'cn-input__input--with-icon': _vm.icon },
      _vm.error && 'cn-input__input--error',
    ],attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":(_setup.valueLocal)},on:{"input":function($event){if($event.target.composing)return;_setup.valueLocal=$event.target.value}}}),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"cn-input__error"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }