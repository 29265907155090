import { useCdnImg } from '@webplatform/cdn-image';

export default () => {
  const getAssetUrl = (image?: string, modifiers = {}): string | undefined => {
    const { generateUrl } = useCdnImg();
    return image ? generateUrl(image, modifiers) : undefined;
  };

  return {
    getAssetUrl,
  };
};
