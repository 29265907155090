import { Context } from '@nuxt/types';

export default ({ route, redirect }: Context) => {
  if (
    route.path === '/' ||
    route.path.endsWith('/') ||
    route.path.includes('.')
  )
    return;
  const { path, query, hash } = route;
  const nextPath = `${path}/`;
  const nextRoute = { path: nextPath, query, hash };

  redirect(301, nextRoute);
};
