import { ref, computed, watch, useRoute, Ref } from '@nuxtjs/composition-api';
import { Params, useFetchNewsArticles } from '@webplatform/nuxt-cms-js-sdk';

interface NewsParams extends Params {
  searchQuery?: Ref;
}

export default ({ searchQuery, type }: NewsParams) => {
  const route = useRoute();
  const currentPage = computed(() => +route.value.query.page);
  const pagesCount = ref<number>();
  const totalCount = ref<number>();

  const {
    meta,
    fetchNews,
    news,
    pending: loading,
  } = useFetchNewsArticles({
    type,
    page: currentPage.value,
    search: searchQuery ? `title:${searchQuery.value}` : undefined,
    searchFields: searchQuery ? 'title:ilike' : undefined,
  });

  const doFetchNews = async () => {
    await fetchNews();
    pagesCount.value = meta.value?.last_page;
    totalCount.value = meta.value?.total as number;
  };

  watch(currentPage, () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    doFetchNews();
  });

  watch(
    () => searchQuery,
    () => {
      doFetchNews();
    },
  );

  return {
    news,
    pagesCount,
    totalCount,
    loading,
    currentPage,
    fetchNews: doFetchNews,
  };
};
