import { ref, watch } from '@nuxtjs/composition-api';
import { useRafFn } from '@vueuse/core';

const useSwiperAutoplayProgress = (swiper: any) => {
  const progress = ref(0);
  const isRunning = ref(false);
  const startTime = ref(Date.now());
  const slideStep = ref(0);

  useRafFn(() => {
    isRunning.value = swiper.value?.$swiper.autoplay.running;
    slideStep.value = swiper.value?.$swiper.activeIndex;

    if (!swiper.value) return;
    if (!isRunning.value) return;
    progress.value = Math.round(
      ((Date.now() - startTime.value) /
        swiper.value.$swiper.passedParams.autoplay.delay) *
        100,
    );
  });

  watch(isRunning, (newValue) => {
    if (!newValue) return;
    startTime.value = Date.now();
  });

  watch(slideStep, () => {
    startTime.value = Date.now();
  });
  return { progress };
};

export default useSwiperAutoplayProgress;
