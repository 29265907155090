import { useStore, ref } from '@nuxtjs/composition-api';

const useSocial = () => {
  const store = useStore();
  const socials = ref(store.getters['config/getSocials']);

  return {
    socials,
  };
};

export default useSocial;
