import { useContext } from '@nuxtjs/composition-api';

const useScrollTrigger = () => {
  const { $ScrollTrigger } = useContext();
  const refresh = () => {
    $ScrollTrigger.refresh();
  };

  return {
    refresh,
  };
};

export default useScrollTrigger;
