const useCountryByLang = (langCode: string) => {
  const countryLangs = {
    uk: 'ua',
    en: 'gb',
    cs: 'cz',
  };
  return countryLangs[langCode as keyof typeof countryLangs] || langCode;
};

export default useCountryByLang;
