import { ref, watch, Ref, reactive, computed } from '@nuxtjs/composition-api';
import {
  Product,
  useFetchDocuments,
  useFetchProducts,
} from '@webplatform/nuxt-cms-js-sdk';

const useFetchDrivers = ({
  searchQuery,
  currentPage,
}: {
  searchQuery: Ref<string>;
  currentPage: Ref<number>;
}) => {
  const productsWithDocs = ref<Product[]>([]);
  const loading = ref<null | boolean>(null);
  const pagesCount = ref();
  const totalCount = ref<null | number>(null);
  const fetchProductsParams = reactive({
    page: currentPage.value,
    search: `name:${searchQuery.value};sku:${searchQuery.value}`,
    searchFields: 'name:ilike;sku:ilike',
    orderBy: 'name' as any,
  });

  const { products, meta, fetchProducts } =
    useFetchProducts(fetchProductsParams);
  const fetchDocumentsParams = reactive({
    product_skus: (products as Ref<Product[]>).value?.map(
      (product) => product.sku,
    ),
  });
  const fetchDocumentsParamsForFetch = computed(() => fetchDocumentsParams);
  const { documents, fetchDocuments } = useFetchDocuments(
    fetchDocumentsParamsForFetch,
    'product',
  );

  const doFetchProducts = async () => {
    loading.value = true;
    try {
      await fetchProducts();
      pagesCount.value = meta.value?.last_page;
      totalCount.value = meta.value?.total as number;
      if (products.value.length) {
        fetchDocumentsParams.product_skus = products.value.map(
          (product: Product) => product.sku,
        );
        await fetchDocuments();
        productsWithDocs.value = (products as Ref<Product[]>).value?.map(
          (product) => {
            return {
              ...product,
              documents: documents.value?.[product.sku],
            };
          },
        );
      }
    } catch (_) {
    } finally {
      loading.value = false;
    }
  };

  watch(searchQuery, (newSearchQuery) => {
    fetchProductsParams.search = `name:${newSearchQuery};sku:${newSearchQuery}`;
    doFetchProducts();
  });

  watch(currentPage, (newCurrentPage) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    fetchProductsParams.page = newCurrentPage;

    doFetchProducts();
  });

  return {
    products: productsWithDocs,
    fetchProducts: doFetchProducts,
    loading,
    pagesCount,
    totalCount,
  };
};

export default useFetchDrivers;
