import { useRoute } from '@nuxtjs/composition-api';
import { Params, useFetchNewsArticle } from '@webplatform/nuxt-cms-js-sdk';

export default ({ type }: Params) => {
  const route = useRoute();
  const { fetchNewsArticle: fetchNews, newsArticle: newsPage } =
    useFetchNewsArticle(route.value.params.slug, {
      type,
    });

  return {
    newsPage,
    fetchNews,
  };
};
