import { computed, ssrRef, ref } from '@nuxtjs/composition-api';
import { Product } from '@webplatform/nuxt-cms-js-sdk';

const useSimilarProducts = () => {
  const product = ssrRef<any>('');
  const currentVariation = ref(0);
  const variations = computed(() => product.value?.variations);

  const similarProducts = computed(() =>
    product.value?.similar_products?.[0].items
      .filter((item: any) => item.active)
      .map((item: any) => ({
        ...item,
        slug: `/product/${item.slug}`,
      })),
  );

  const activeSimilarProduct = computed(() =>
    similarProducts?.value?.find((item: any) => item.selected),
  );

  const setSimilarProduct = (index: number) => {
    product.value.similar_products[0].items =
      product.value.similar_products[0].items.map(
        (item: Product, idx: number) => {
          return {
            ...item,
            selected: index === idx,
          };
        },
      );
  };

  const setVariation = (index: number) => {
    currentVariation.value = index;
  };

  const setProduct = (payload: Product) => {
    product.value = payload;
  };

  return {
    currentVariation,
    variations,
    activeSimilarProduct,
    similarProducts,
    setSimilarProduct,
    setVariation,
    setProduct,
  };
};

export default useSimilarProducts;
