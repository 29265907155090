import { useContext, ref } from '@nuxtjs/composition-api';
import { City, Ids } from '@webplatform/nuxt-cms-js-sdk';

export default () => {
  const {
    $api: { serviceCentersService, citiesService },
  } = useContext();
  const cities = ref<City[]>([]);
  const loading = ref(false);

  const fetchCities = async (countryId: number) => {
    try {
      loading.value = true;
      const { data: servicesData } =
        await serviceCentersService.getServicesCitiesByCountryId(countryId, {});
      if (servicesData.length) {
        const { data: citiesData } = await citiesService.getCitiesByIds({
          city_id: servicesData.map((s: Ids) => s.id),
        });
        cities.value = citiesData;
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };
  return {
    cities,
    loading,
    fetchCities,
  };
};
