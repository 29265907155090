import { render, staticRenderFns } from "./LanguageSwitcher.vue?vue&type=template&id=316507a6"
import script from "./LanguageSwitcher.vue?vue&type=script&setup=true&lang=ts"
export * from "./LanguageSwitcher.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LanguageSwitcher.vue?vue&type=style&index=0&id=316507a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports