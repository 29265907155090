import { computed } from 'vue';
// import { type ButtonVariant } from '@/components/ui/UiButton.vue';

type ButtonVariant =
  | 'gray'
  | 'red'
  | 'aqua'
  | 'yellow'
  | 'blue'
  | 'red-outlined'
  | 'pink';

export type Color =
  | 'gray'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'black'
  | 'white'
  | 'blue-gray';

export type AccentColor = 'red' | 'yellow' | 'aqua' | 'blue' | 'pink' | 'gray';

const figuresColors: Record<Color, string> = {
  gray: 'border-yellow',
  red: 'border-white',
  yellow: 'border-white',
  blue: 'border-aqua',
  black: 'border-yellow',
  white: 'border-red',
  'blue-gray': 'border-yellow',
};

const figuresColorsAccent: Record<AccentColor, string> = {
  red: 'border-red',
  yellow: 'border-yellow',
  aqua: 'border-aqua',
  blue: 'border-blue-dark',
  pink: 'border-pink',
  gray: 'border-gray',
};

const buttonColors: Record<string, ButtonVariant> = {
  gray: 'yellow',
  red: 'yellow',
  yellow: 'blue',
  blue: 'aqua',
  black: 'yellow',
  white: 'red',
  'blue-gray': 'yellow',
};

const buttonColorsAccent: Record<string, ButtonVariant> = {
  red: 'red',
  yellow: 'yellow',
  aqua: 'aqua',
  blue: 'blue',
  pink: 'pink',
  gray: 'gray',
};

const bgColors = {
  gray: 'bg-gray-light',
  red: 'bg-red',
  yellow: 'bg-yellow',
  blue: 'bg-blue-dark',
  black: 'bg-black',
  white: 'bg-gray-light',
  'blue-gray': 'bg-gray-light',
};

const textColors = {
  gray: 'text-black',
  red: 'text-white',
  yellow: 'text-black',
  blue: 'text-white',
  black: 'text-white',
  white: 'text-black',
  'blue-gray': 'text-white',
};

const linesColors = {
  blue: 'linear-gradient(189.04deg, #132a45 0.51%, #081b2f 89.08%)',
  red: 'linear-gradient(189.04deg, #f30b00 0.51%, #ff3b31 89.08%)',
  gray: 'linear-gradient(189.04deg, #efefef 0.51%, #fcfcfc 89.08%)',
  yellow: 'linear-gradient(186.36deg, #e7ed3e -3.75%, #dbe03b 88.61%)',
  black: [
    'linear-gradient(189.04deg, rgba(0, 0, 0, 0.33) 0.51%, #13161A 89.08%)',
    'linear-gradient(189.04deg, rgba(0, 0, 0, 0.76) 0.51%, #13161A 89.08%)',
  ],
  white: [
    'linear-gradient(189.04deg, rgba(160, 160, 160, 0.76) 0.51%, #F5F5F5 89.08%)',
    'linear-gradient(234.45deg, rgba(255, 255, 255, 0.24) 19.01%, rgba(255, 255, 255, 0.806143) 45.95%, rgba(255, 255, 255, 0.856643) 49.96%, rgba(255, 255, 255, 0.89) 56.17%)',
  ],
  'blue-gray': [
    'linear-gradient(234.45deg, rgba(120, 145, 182, 0.71) 19.01%, #6985AC 56.17%)',
    'linear-gradient(234.45deg, rgba(94, 123, 165, 0.71) 19.01%, #617EA6 56.17%)',
  ],
};

interface Params {
  color: Color;
  accentColor?: AccentColor;
}

export default function useCategoryBanner({ color, accentColor }: Params) {
  const figuresColor = computed(() => {
    return accentColor
      ? figuresColorsAccent[accentColor]
      : figuresColors[color];
  });

  const buttonColor = computed<ButtonVariant>(() => {
    return accentColor ? buttonColorsAccent[accentColor] : buttonColors[color];
  });

  const bgColor = computed(() => {
    return bgColors[color];
  });

  const lineColors = computed(() => {
    const result = linesColors[color];
    if (typeof result === 'string') {
      return [result, result];
    }
    return result;
  });

  const textColor = computed(() => {
    return textColors[color];
  });

  return {
    figuresColor,
    buttonColor,
    bgColor,
    lineColors,
    textColor,
  };
}
