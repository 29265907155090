import { useContext } from '@nuxtjs/composition-api';

const useRecaptcha = () => {
  const { $axios, $recaptcha } = useContext();

  const getToken = async () => {
    // eslint-disable-next-line no-return-await
    return await $recaptcha.getResponse();
  };

  const validateToken = async (token: string) => {
    const response = await $axios.post('/check-recaptcha-token', { token });
    return response.data;
  };

  const resetRecaptcha = () => {
    $recaptcha.reset();
  };

  const validateRecaptcha = async () => {
    try {
      const token = await getToken();
      const result = await validateToken(token);
      return result?.success === true;
    } catch (error) {
      return false;
    } finally {
      resetRecaptcha();
    }
  };

  return {
    validateRecaptcha,
  };
};

export default useRecaptcha;
