import { useFetchPage } from '@webplatform/nuxt-cms-js-sdk';

const usePage = (slug: string) => {
  const {
    fetchPage,
    page,
    pending: isLoading,
  } = useFetchPage(slug, { type: 'page' });

  return {
    page,
    fetchPage,
    isLoading,
  };
};

export default usePage;
