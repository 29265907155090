import { ref } from '@nuxtjs/composition-api';

const useAccordeon = () => {
  const activeItem = ref<any>(null);

  const setActiveItem = (index: any) => {
    if (activeItem.value === index) {
      activeItem.value = -1;
    } else {
      activeItem.value = index;
    }
  };

  return { activeItem, setActiveItem };
};

export default useAccordeon;
