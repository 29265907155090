import { useFetchFaqCategories } from '@webplatform/nuxt-cms-js-sdk';

export default () => {
  const { faqCategories: categories, fetchFaqCategories: fetchCategories } =
    useFetchFaqCategories({});

  return {
    categories,
    fetchCategories,
  };
};
