import { Ref, ref, watch, reactive } from '@nuxtjs/composition-api';
import { useFetchFaqs } from '@webplatform/nuxt-cms-js-sdk';

export default ({
  currentPage,
  searchQuery,
  categoryId,
}: {
  currentPage: Ref<number>;
  searchQuery: Ref<string>;
  categoryId: Ref<number>;
}) => {
  const pagesCount = ref();
  const totalCount = ref<null | number>(null);
  const fetchParams = reactive({
    category_id: categoryId ? categoryId.value : undefined,
    'search[title]': searchQuery.value,
    page: currentPage.value,
  });

  const {
    faqs: questions,
    fetchFaqs: fetchQuestions,
    pending: loading,
    meta,
  } = useFetchFaqs(fetchParams);

  const doFetchQuestions = async () => {
    await fetchQuestions();
    pagesCount.value = meta.value?.last_page;
    totalCount.value = meta.value?.total as number;
  };

  watch(currentPage, (newCurrentPage) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    fetchParams.page = newCurrentPage;
    doFetchQuestions();
  });

  watch(searchQuery, (newSearchQuery) => {
    fetchParams['search[title]'] = newSearchQuery;
    doFetchQuestions();
  });
  watch(categoryId, (newCategoryId) => {
    fetchParams.category_id = newCategoryId;
    doFetchQuestions();
  });

  return {
    questions,
    pagesCount,
    loading,
    fetchQuestions: doFetchQuestions,
    totalCount,
  };
};
