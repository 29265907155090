import Vue from 'vue';
import GmapVue, { components } from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCX-RzM0fCY5C92g_ijwJoVPW1vs8ljL2s',
    libraries: 'places',
  },
  installComponents: true,
  dynamicLoad: false,
});

Vue.component('GmapCluster', components.Cluster);
