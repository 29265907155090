import Vue from 'vue';
import PrimeVue from 'primevue/config';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

Vue.use(PrimeVue, {
  unstyled: true,
});

Vue.component('PVTabs', TabView);
Vue.component('PVTabPanel', TabPanel);
Vue.component('PVAccordion', Accordion);
Vue.component('PVAccordionItem', AccordionTab);
