
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  useRoute,
  useRouter,
  useStore,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import { usePaginationPageMeta } from '@/composables';
import { useFetchNewsArticles } from '@webplatform/nuxt-cms-js-sdk';
import { rawGtm } from '@/helpers/gtmRawString';

export default defineComponent({
  setup() {
    const {
      $api: { bannerGroupsService },
    } = useContext();

    const route = useRoute();
    const router = useRouter();
    const isMainPage = computed(() => route.value.name?.startsWith('index'));
    const mainFromCanion = ref(null);
    const store = useStore();
    const menuMain = store.getters['menus/getMenuMain'];
    const menuFooter = store.getters['menus/getMenuFooter'];

    const contactFormModal = ref(null);
    const supportFormModal = ref(null);

    const openModals = (hash) => {
      if (hash === '#contact-form') contactFormModal.value.open();
      if (hash === '#support-form') supportFormModal.value.open();
    };

    watch(
      () => route.value.hash,
      (newValue) => {
        openModals(newValue);
      },
    );

    onMounted(() => {
      setTimeout(() => {
        openModals(route.value.hash);
      }, 300);
    });

    const handleCloseModal = () => {
      router.push({ ...route.value, hash: '' });
    };

    usePaginationPageMeta();

    const pages = [
      '/user-help-desk',
      '/search',
      '/news',
      '/e-catalog',
      '/stocks',
      '/drivers-and-manuals',
    ];
    const showMainFromCanyon = computed(
      () => !pages.some((p) => route.value.path.includes(p)),
    );

    const fetchMainFromCanyon = async () => {
      try {
        const { data } = await bannerGroupsService.getBannerGroupsByKey({
          key: 'general_about_canyon',
        });
        mainFromCanion.value = data;
      } catch (error) {}
    };

    const { news: promotions, fetchNews: fetchPromotions } =
      useFetchNewsArticles({
        type: 'promotions',
      });

    useFetch(async () => {
      await fetchMainFromCanyon();
      await fetchPromotions();
    });

    const mainFromCanionBanners = computed(() => {
      return mainFromCanion.value?.banners.map((b) => {
        return {
          images: [
            b.content?.bannerSizes?.[0].image.file?.path,
            b.content?.bannerSizes?.[1].image.file?.path,
          ],
          title: b.content?.bannerSizes?.[0].title,
          description: b.content?.bannerSizes?.[0].description,
          link: b?.url?.replace(/^"(.*)"$/, '$1'),
        };
      });
    });

    return {
      menuMain,
      menuFooter,
      mainFromCanion,
      mainFromCanionBanners,
      isMainPage,
      showMainFromCanyon,
      promotions,
      contactFormModal,
      supportFormModal,
      handleCloseModal,
    };
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const baseURL = this.$baseURL;
    const { theme } = this.$config;
    const path =
      this.$route.path !== '/' ? baseURL + this.$route.path : baseURL;
    const GSVMetas = this.$store.getters['config/getGSVMetas'];
    const colorMode = theme === 'cng' ? 'dark' : 'light';
    const store = this.$store;
    const favicon = store.getters['config/getFavicon'];
    const gtmId = store.getters['config/getGTM'];
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
        class: colorMode,
      },
      link: [
        { rel: 'icon', type: 'image/x-icon', href: favicon ?? '/favicon.ico' },
      ],
      bodyAttrs: {
        class: `theme-${theme}`,
      },
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: path,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${baseURL}/img/canyon-og.png`,
        },
        ...GSVMetas,
        ...i18nHead.meta,
      ],
      ...(gtmId && {
        script: [
          {
            hid: 'gtm-script',
            type: 'text/javascript',
            charset: 'utf-8',
            innerHTML: rawGtm.getScript(gtmId),
          },
        ],
        noscript: [
          {
            hid: 'gtm-iframe',
            pbody: true,
            innerHTML: rawGtm.getIframe(gtmId),
          },
        ],
        __dangerouslyDisableSanitizersByTagID: {
          'gtm-script': ['innerHTML'],
          'gtm-iframe': ['innerHTML'],
        },
      }),
    };
  },
  jsonld() {
    const baseURL = this.$baseURL;

    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          url: baseURL,
          logo: `${baseURL}/svg/logo.svg`,
          sameAs: [
            'https://www.facebook.com/CanyonTech',
            'https://twitter.com/canyontech',
            'https://www.youtube.com/user/canyonacc',
            'https://vk.com/canyonru',
            'https://www.instagram.com/canyon_accessories/',
          ],
          email: 'info@canyon.eu',
          name: 'Canyon',
          brand: {
            '@type': 'Brand',
            name: 'Canyon',
          },
        },
      ],
    };
  },
});
