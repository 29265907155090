export const AboutCard = () => import('../../components/AboutCard.vue' /* webpackChunkName: "components/about-card" */).then(c => wrapFunctional(c.default || c))
export const AboutCardText = () => import('../../components/AboutCardText.vue' /* webpackChunkName: "components/about-card-text" */).then(c => wrapFunctional(c.default || c))
export const AboutCards = () => import('../../components/AboutCards.vue' /* webpackChunkName: "components/about-cards" */).then(c => wrapFunctional(c.default || c))
export const AboutGaming = () => import('../../components/AboutGaming.vue' /* webpackChunkName: "components/about-gaming" */).then(c => wrapFunctional(c.default || c))
export const AboutHeader = () => import('../../components/AboutHeader.vue' /* webpackChunkName: "components/about-header" */).then(c => wrapFunctional(c.default || c))
export const AboutImage = () => import('../../components/AboutImage.vue' /* webpackChunkName: "components/about-image" */).then(c => wrapFunctional(c.default || c))
export const AboutText = () => import('../../components/AboutText.vue' /* webpackChunkName: "components/about-text" */).then(c => wrapFunctional(c.default || c))
export const AccountRemovalForm = () => import('../../components/AccountRemovalForm.vue' /* webpackChunkName: "components/account-removal-form" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCookies = () => import('../../components/AppCookies.vue' /* webpackChunkName: "components/app-cookies" */).then(c => wrapFunctional(c.default || c))
export const AppError = () => import('../../components/AppError.vue' /* webpackChunkName: "components/app-error" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppFooterMenu = () => import('../../components/AppFooterMenu.vue' /* webpackChunkName: "components/app-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const AppFooterMenuMobile = () => import('../../components/AppFooterMenuMobile.vue' /* webpackChunkName: "components/app-footer-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenu = () => import('../../components/AppHeaderMenu.vue' /* webpackChunkName: "components/app-header-menu" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuItem = () => import('../../components/AppHeaderMenuItem.vue' /* webpackChunkName: "components/app-header-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMobile = () => import('../../components/AppHeaderMobile.vue' /* webpackChunkName: "components/app-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMobileMenu = () => import('../../components/AppHeaderMobileMenu.vue' /* webpackChunkName: "components/app-header-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMobileSearch = () => import('../../components/AppHeaderMobileSearch.vue' /* webpackChunkName: "components/app-header-mobile-search" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppMap = () => import('../../components/AppMap.vue' /* webpackChunkName: "components/app-map" */).then(c => wrapFunctional(c.default || c))
export const AppPromotions = () => import('../../components/AppPromotions.vue' /* webpackChunkName: "components/app-promotions" */).then(c => wrapFunctional(c.default || c))
export const AppSearch = () => import('../../components/AppSearch.vue' /* webpackChunkName: "components/app-search" */).then(c => wrapFunctional(c.default || c))
export const CategoryBannerLarge = () => import('../../components/CategoryBannerLarge.vue' /* webpackChunkName: "components/category-banner-large" */).then(c => wrapFunctional(c.default || c))
export const CategoryBannerSmall = () => import('../../components/CategoryBannerSmall.vue' /* webpackChunkName: "components/category-banner-small" */).then(c => wrapFunctional(c.default || c))
export const CategoryBannerSmallGroup = () => import('../../components/CategoryBannerSmallGroup.vue' /* webpackChunkName: "components/category-banner-small-group" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCards = () => import('../../components/CategoryCards.vue' /* webpackChunkName: "components/category-cards" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardsSection = () => import('../../components/CategoryCardsSection.vue' /* webpackChunkName: "components/category-cards-section" */).then(c => wrapFunctional(c.default || c))
export const CategoryHeader = () => import('../../components/CategoryHeader.vue' /* webpackChunkName: "components/category-header" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const DriverCardSkeleton = () => import('../../components/DriverCardSkeleton.vue' /* webpackChunkName: "components/driver-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const DriversCard = () => import('../../components/DriversCard.vue' /* webpackChunkName: "components/drivers-card" */).then(c => wrapFunctional(c.default || c))
export const DriversList = () => import('../../components/DriversList.vue' /* webpackChunkName: "components/drivers-list" */).then(c => wrapFunctional(c.default || c))
export const FaqQuestions = () => import('../../components/FaqQuestions.vue' /* webpackChunkName: "components/faq-questions" */).then(c => wrapFunctional(c.default || c))
export const FaqSkeleton = () => import('../../components/FaqSkeleton.vue' /* webpackChunkName: "components/faq-skeleton" */).then(c => wrapFunctional(c.default || c))
export const FormSection = () => import('../../components/FormSection.vue' /* webpackChunkName: "components/form-section" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const MainFromCanionMainBlockLarge = () => import('../../components/MainFromCanionMainBlockLarge.vue' /* webpackChunkName: "components/main-from-canion-main-block-large" */).then(c => wrapFunctional(c.default || c))
export const MainFromCanionMainBlockSmall = () => import('../../components/MainFromCanionMainBlockSmall.vue' /* webpackChunkName: "components/main-from-canion-main-block-small" */).then(c => wrapFunctional(c.default || c))
export const MainFromCanyon = () => import('../../components/MainFromCanyon.vue' /* webpackChunkName: "components/main-from-canyon" */).then(c => wrapFunctional(c.default || c))
export const MainFromCanyonMain = () => import('../../components/MainFromCanyonMain.vue' /* webpackChunkName: "components/main-from-canyon-main" */).then(c => wrapFunctional(c.default || c))
export const MainSlider = () => import('../../components/MainSlider.vue' /* webpackChunkName: "components/main-slider" */).then(c => wrapFunctional(c.default || c))
export const ModalShopList = () => import('../../components/ModalShopList.vue' /* webpackChunkName: "components/modal-shop-list" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const NewsList = () => import('../../components/NewsList.vue' /* webpackChunkName: "components/news-list" */).then(c => wrapFunctional(c.default || c))
export const NewsSection = () => import('../../components/NewsSection.vue' /* webpackChunkName: "components/news-section" */).then(c => wrapFunctional(c.default || c))
export const NewsSectionSkeleton = () => import('../../components/NewsSectionSkeleton.vue' /* webpackChunkName: "components/news-section-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PageBlocks = () => import('../../components/PageBlocks.vue' /* webpackChunkName: "components/page-blocks" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSkeleton = () => import('../../components/ProductCardSkeleton.vue' /* webpackChunkName: "components/product-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductCharacteristics = () => import('../../components/ProductCharacteristics.vue' /* webpackChunkName: "components/product-characteristics" */).then(c => wrapFunctional(c.default || c))
export const ProductImagesSlider = () => import('../../components/ProductImagesSlider.vue' /* webpackChunkName: "components/product-images-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductPageSkeleton = () => import('../../components/ProductPageSkeleton.vue' /* webpackChunkName: "components/product-page-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductShops = () => import('../../components/ProductShops.vue' /* webpackChunkName: "components/product-shops" */).then(c => wrapFunctional(c.default || c))
export const ProductsCatalog = () => import('../../components/ProductsCatalog.vue' /* webpackChunkName: "components/products-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProductsList = () => import('../../components/ProductsList.vue' /* webpackChunkName: "components/products-list" */).then(c => wrapFunctional(c.default || c))
export const ProseBlock = () => import('../../components/ProseBlock.vue' /* webpackChunkName: "components/prose-block" */).then(c => wrapFunctional(c.default || c))
export const RichAdvantages = () => import('../../components/RichAdvantages.vue' /* webpackChunkName: "components/rich-advantages" */).then(c => wrapFunctional(c.default || c))
export const RichAdvantagesList = () => import('../../components/RichAdvantagesList.vue' /* webpackChunkName: "components/rich-advantages-list" */).then(c => wrapFunctional(c.default || c))
export const RichFooter = () => import('../../components/RichFooter.vue' /* webpackChunkName: "components/rich-footer" */).then(c => wrapFunctional(c.default || c))
export const RichHeader = () => import('../../components/RichHeader.vue' /* webpackChunkName: "components/rich-header" */).then(c => wrapFunctional(c.default || c))
export const RichImagesGrid = () => import('../../components/RichImagesGrid.vue' /* webpackChunkName: "components/rich-images-grid" */).then(c => wrapFunctional(c.default || c))
export const RichVideo = () => import('../../components/RichVideo.vue' /* webpackChunkName: "components/rich-video" */).then(c => wrapFunctional(c.default || c))
export const SearchHeader = () => import('../../components/SearchHeader.vue' /* webpackChunkName: "components/search-header" */).then(c => wrapFunctional(c.default || c))
export const SearchNotFound = () => import('../../components/SearchNotFound.vue' /* webpackChunkName: "components/search-not-found" */).then(c => wrapFunctional(c.default || c))
export const SearchTitle = () => import('../../components/SearchTitle.vue' /* webpackChunkName: "components/search-title" */).then(c => wrapFunctional(c.default || c))
export const SectionHeader = () => import('../../components/SectionHeader.vue' /* webpackChunkName: "components/section-header" */).then(c => wrapFunctional(c.default || c))
export const SeoText = () => import('../../components/SeoText.vue' /* webpackChunkName: "components/seo-text" */).then(c => wrapFunctional(c.default || c))
export const ServiceCenters = () => import('../../components/ServiceCenters.vue' /* webpackChunkName: "components/service-centers" */).then(c => wrapFunctional(c.default || c))
export const ShopCard = () => import('../../components/ShopCard.vue' /* webpackChunkName: "components/shop-card" */).then(c => wrapFunctional(c.default || c))
export const ShopCardSkeleton = () => import('../../components/ShopCardSkeleton.vue' /* webpackChunkName: "components/shop-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ShopList = () => import('../../components/ShopList.vue' /* webpackChunkName: "components/shop-list" */).then(c => wrapFunctional(c.default || c))
export const SubcategoryHeader = () => import('../../components/SubcategoryHeader.vue' /* webpackChunkName: "components/subcategory-header" */).then(c => wrapFunctional(c.default || c))
export const SupportForm = () => import('../../components/SupportForm.vue' /* webpackChunkName: "components/support-form" */).then(c => wrapFunctional(c.default || c))
export const TipsSection = () => import('../../components/TipsSection.vue' /* webpackChunkName: "components/tips-section" */).then(c => wrapFunctional(c.default || c))
export const TipsSectionAccordion = () => import('../../components/TipsSectionAccordion.vue' /* webpackChunkName: "components/tips-section-accordion" */).then(c => wrapFunctional(c.default || c))
export const TipsSectionTabs = () => import('../../components/TipsSectionTabs.vue' /* webpackChunkName: "components/tips-section-tabs" */).then(c => wrapFunctional(c.default || c))
export const UserHelpCards = () => import('../../components/UserHelpCards.vue' /* webpackChunkName: "components/user-help-cards" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../components/ui/UiAccordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionItem = () => import('../../components/ui/UiAccordionItem.vue' /* webpackChunkName: "components/ui-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionNew = () => import('../../components/ui/UiAccordionNew.vue' /* webpackChunkName: "components/ui-accordion-new" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/UiButton.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiButtonInner = () => import('../../components/ui/UiButtonInner.vue' /* webpackChunkName: "components/ui-button-inner" */).then(c => wrapFunctional(c.default || c))
export const UiCheap = () => import('../../components/ui/UiCheap.vue' /* webpackChunkName: "components/ui-cheap" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiChip = () => import('../../components/ui/UiChip.vue' /* webpackChunkName: "components/ui-chip" */).then(c => wrapFunctional(c.default || c))
export const UiColorSelect = () => import('../../components/ui/UiColorSelect.vue' /* webpackChunkName: "components/ui-color-select" */).then(c => wrapFunctional(c.default || c))
export const UiDrawer = () => import('../../components/ui/UiDrawer.vue' /* webpackChunkName: "components/ui-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/ui/UiIcon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiImagesGallery = () => import('../../components/ui/UiImagesGallery.vue' /* webpackChunkName: "components/ui-images-gallery" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/UiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiLink = () => import('../../components/ui/UiLink.vue' /* webpackChunkName: "components/ui-link" */).then(c => wrapFunctional(c.default || c))
export const UiList = () => import('../../components/ui/UiList.vue' /* webpackChunkName: "components/ui-list" */).then(c => wrapFunctional(c.default || c))
export const UiModal = () => import('../../components/ui/UiModal.vue' /* webpackChunkName: "components/ui-modal" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/UiPagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiParallelogram = () => import('../../components/ui/UiParallelogram.vue' /* webpackChunkName: "components/ui-parallelogram" */).then(c => wrapFunctional(c.default || c))
export const UiSearchForm = () => import('../../components/ui/UiSearchForm.vue' /* webpackChunkName: "components/ui-search-form" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/UiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSkeletonElement = () => import('../../components/ui/UiSkeletonElement.vue' /* webpackChunkName: "components/ui-skeleton-element" */).then(c => wrapFunctional(c.default || c))
export const UiSpinner = () => import('../../components/ui/UiSpinner.vue' /* webpackChunkName: "components/ui-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/UiTabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiTabsNew = () => import('../../components/ui/UiTabsNew.vue' /* webpackChunkName: "components/ui-tabs-new" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/UiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiTreeview = () => import('../../components/ui/UiTreeview.vue' /* webpackChunkName: "components/ui-treeview" */).then(c => wrapFunctional(c.default || c))
export const UiTreeviewFilter = () => import('../../components/ui/UiTreeviewFilter.vue' /* webpackChunkName: "components/ui-treeview-filter" */).then(c => wrapFunctional(c.default || c))
export const UiIconCookie = () => import('../../components/ui/icons/UiIconCookie.vue' /* webpackChunkName: "components/ui-icon-cookie" */).then(c => wrapFunctional(c.default || c))
export const UiIconMessage = () => import('../../components/ui/icons/UiIconMessage.vue' /* webpackChunkName: "components/ui-icon-message" */).then(c => wrapFunctional(c.default || c))
export const UiIconSearchNotFound = () => import('../../components/ui/icons/UiIconSearchNotFound.vue' /* webpackChunkName: "components/ui-icon-search-not-found" */).then(c => wrapFunctional(c.default || c))
export const UiIconYouCan = () => import('../../components/ui/icons/UiIconYouCan.vue' /* webpackChunkName: "components/ui-icon-you-can" */).then(c => wrapFunctional(c.default || c))
export const UiIcons = () => import('../../components/ui/icons/icons.ts' /* webpackChunkName: "components/ui-icons" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
