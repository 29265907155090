import { computed, useStore, useRoute } from '@nuxtjs/composition-api';

interface Domain {
  domain: string;
  gtm: null | string;
  indexing: Boolean;
  contacts: any[];
  languages: any[];
  social_links: any[];
  language: any[];
  protocol?: string;
}

// TODO: Узнать, можно ли в cms указать 4хзначные коды для доменов
export default () => {
  const route = useRoute();
  const store = useStore();
  const domains: Domain[] = store.getters['config/getDomains'];

  const FOUR_DIGIT_DOMAINS = [
    {
      domain: 'canyon.ng',
      code: 'en-ng',
    },
    {
      domain: 'canyon.ke',
      code: 'en-ke',
    },
  ];

  const alternateLinks = computed(() => {
    return domains?.map((domain) => {
      const domainProtocol = domain.protocol ?? 'https';
      const fourDigitDomainFromCms = FOUR_DIGIT_DOMAINS.find(
        (fourDigitDomain) => fourDigitDomain.domain === domain.domain,
      );

      if (fourDigitDomainFromCms !== undefined)
        return {
          rel: 'alternate',
          href: `${domainProtocol}://${fourDigitDomainFromCms.domain}${route.value.path}`,
          hreflang: fourDigitDomainFromCms.code,
        };
      return {
        rel: 'alternate',
        href: `${domainProtocol}://${domain.domain}${route.value.path}`,
        hreflang: domain.language.find((lang) => lang.default === true)?.code,
      };
    });
  });

  return {
    alternateLinks,
  };
};
