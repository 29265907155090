import {
  ref,
  ssrRef,
  computed,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';

const PAGE_MAX_PRODUCTS = 30;

const useFetchCategory = () => {
  const {
    $api: { categoriesService },
  } = useContext();
  const router = useRouter();
  const loading = ref(false);
  const category = ssrRef<any>('');

  const products = computed(() => category?.value?.products);

  const fetchCategoryBySlug = async ({
    slug,
    page,
    categories,
  }: {
    slug: string;
    page: any;
    categories: any;
  }) => {
    try {
      loading.value = true;
      router.push({
        query: {
          categories:
            categories &&
            (categories.length ? categories.join(',') : undefined),
          page,
        },
      });

      const { data } = await categoriesService.getCategoryBySlug(slug, {
        page,
        category_id: categories,
        includes: ['variations'] as any,
        // @ts-ignore
        'order[popular]': 'desc',
      });

      category.value = data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  const totalPages = computed({
    get() {
      if (!category.value) return null;

      return Math.ceil(category.value.products_meta_count / PAGE_MAX_PRODUCTS);
    },
    set() {},
  });

  return {
    totalPages,
    category,
    products,
    loading,
    fetchCategoryBySlug,
  };
};

export default useFetchCategory;
