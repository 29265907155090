import { useContext, ref } from '@nuxtjs/composition-api';

const useFetchProductShops = () => {
  const { $axios } = useContext();
  const productShops = ref();
  const loading = ref(false);
  const fetchProductShops = async (id: string) => {
    loading.value = true;
    try {
      const { data } = await $axios.get(`/api/shops/product/${id}`);

      productShops.value =
        data.data?.map((shop: any) => ({
          id: shop.id,
          name: shop.name ?? undefined,
          image: shop.image ?? undefined,
          url: shop.link ?? undefined,
          currency: shop.currency ?? undefined,
          price: shop.price ?? undefined,
        })) ?? [];
    } finally {
      loading.value = false;
    }
  };

  return {
    productShops,
    loading,
    fetchProductShops,
  };
};

export default useFetchProductShops;
