/* eslint-disable no-shadow */
export const state = () => ({
  categories: [],
});

export const getters = {
  getAllCategories(state) {
    return state.categories;
  },
};

export const mutations = {
  setCategories(state, categories) {
    state.categories.push(...categories);
  },
};

export const actions = {
  async load({ commit }, { $api: { categoriesService } }) {
    try {
      const { data: categories } = await categoriesService.getAllCategories({
        includes: ['seo'],
      });
      commit('setCategories', categories);
    } catch (error) {
      /* eslint-disable no-console */
      console.error(error.response?.data ?? error);
    }
  },
};
