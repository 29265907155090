var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"banner",staticClass:"cn-category-banner-small",class:[`cn-category-banner-small--${_vm.data.color}`]},[_c('div',{staticClass:"cn-category-banner-small__lines-and-boxes"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-small__boxes",class:{
        [`cn-category-banner-small__boxes--${_vm.data.color}`]:
          _vm.data.color && !_vm.data.accentColor,
        [`cn-category-banner-small__boxes--accent-${_vm.data.accentColor}`]:
          _vm.data.accentColor,
      }},[_c('div',{staticClass:"box",class:{
          'box--first': _vm.data.position === 1,
        }}),_vm._v(" "),_c('div',{staticClass:"box",class:{
          'box--first': _vm.data.position === 1,
        }}),_vm._v(" "),_c('div',{staticClass:"box",class:{
          'box--first': _vm.data.position === 1,
        }})])]),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-small__content-wrapper"},[_c('div',{staticClass:"cn-category-banner-small__content"},[_c(_setup.CdnImg,{staticClass:"image",attrs:{"src":_vm.data.image,"width":350,"height":350,"format":"webp","alt":_vm.data.title}}),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-small__text"},[_c('div',[_c('h3',{staticClass:"cn-category-banner-small__title"},[_vm._v("\n            "+_vm._s(_vm.data.title)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"cn-category-banner-small__description"},[_vm._v("\n            "+_vm._s(_vm.data.description)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-small__controls"},[(_vm.data.link)?_c('UiButton',{attrs:{"variant":_setup.buttonColor,"to":_vm.data.link.src}},[_vm._v("\n            "+_vm._s(_vm.$t('more'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.data.modelsCount)?_c('div',{staticClass:"cn-category-banner-small__models-count"},[_c('span',[_vm._v("\n              "+_vm._s(_vm.data.modelsCount)+"\n            ")]),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.$tc('model', _vm.data.modelsCount))+"\n            ")])]):_vm._e()],1)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cn-category-banner-small__lines"},[_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }