import { readBody } from 'h3';
import { ofetch } from 'ofetch';

// const IS_DEV = process.env.NODE_ENV === 'development';
const SECRET_KEY = process.env.RECAPTCHA_PRIVATE_KEY;

export default async (req, res) => {
  res.setHeader('Content-Type', 'application/json');

  try {
    const { token } = await readBody({ node: { req }, method: 'POST' });

    if (!token) {
      res.end(
        JSON.stringify({
          success: false,
          message: 'Invalid token',
        }),
      );
      return;
    }

    const response = await ofetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY}&response=${token}`,
      {
        method: 'POST',
      },
    );

    res.end(
      JSON.stringify({
        success: response.success,
        response,
      }),
    );
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error);
    res.end(
      JSON.stringify({
        success: false,
        message: 'Internal error',
        error,
      }),
    );
  }
};
