import { useMeta, useRoute } from '@nuxtjs/composition-api';

export default () => {
  const route = useRoute();
  const { meta } = useMeta();
  if (route.value.query.page)
    meta.value.push({
      name: 'robots',
      content: 'noindex, follow',
    });
};
