var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"banner",staticClass:"cn-category-banner-large",class:[`cn-category-banner-large--${_vm.data.color}`]},[_c('div',{staticClass:"cn-category-banner-large__bg"},[(
        _vm.data.bgImage &&
        _vm.data.bgImage.desktop &&
        _vm.data.bgImage.desktop.src &&
        _vm.data.bgImage.mobile &&
        _vm.data.bgImage.mobile.src
      )?_c('picture',[(_vm.data.bgImage.largeDesktop && _vm.data.bgImage.largeDesktop.src)?_c('source',{attrs:{"media":"(min-width: 3840px)","srcset":_setup.getAssetUrl(_vm.data.bgImage.largeDesktop.src)}}):_vm._e(),_vm._v(" "),(_vm.data.bgImage.desktop && _vm.data.bgImage.desktop.src)?_c('source',{attrs:{"media":"(min-width: 768px)","srcset":_setup.getAssetUrl(_vm.data.bgImage.desktop.src)}}):_vm._e(),_vm._v(" "),(_vm.data.bgImage.mobile && _vm.data.bgImage.mobile.src)?_c('img',{attrs:{"src":_setup.getAssetUrl(_vm.data.bgImage.mobile.src),"alt":_vm.data.bgImage.alt}}):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-large__lines-block"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"cn-category-banner-large__boxes",class:{
          [`cn-category-banner-large__boxes--${_vm.data.color}`]:
            _vm.data.color && !_vm.data.accentColor,
          [`cn-category-banner-large__boxes--accent-${_vm.data.accentColor}`]:
            _vm.data.accentColor,
        }},[_c('div',{staticClass:"box"}),_vm._v(" "),_c('div',{staticClass:"box"})])])]),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-large__block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"cn-category-banner-large__block-wrapper"},[_c('div',{staticClass:"cn-category-banner-large__content"},[_c('h2',[_vm._v("\n            "+_vm._s(_vm.data.title)+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.data.description)+"\n          ")]),_vm._v(" "),(_vm.data.link)?_c('div',{staticClass:"cn-category-banner-large__controls"},[_c('UiButton',{attrs:{"variant":_setup.buttonColor,"to":_vm.data.link.src}},[_vm._v("\n              "+_vm._s(_vm.$t('more'))+"\n            ")])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cn-category-banner-large__image-block"},[(_vm.data.image)?_c('div',{staticClass:"cn-category-banner-large__image"},[_c('div',{staticClass:"cn-category-banner-large__image-boxes",class:{
                [`cn-category-banner-large__image-boxes--${_vm.data.color}`]:
                  _vm.data.color && !_vm.data.accentColor,
                [`cn-category-banner-large__image-boxes--accent-${_vm.data.accentColor}`]:
                  _vm.data.accentColor,
              }},[_c('div',{staticClass:"image-box",staticStyle:{"clip-path":"polygon(100% 100%, 0 100%, 100% 0)"}}),_vm._v(" "),_c('div',{staticClass:"image-box",staticStyle:{"clip-path":"polygon(0 0, 0 100%, 100% 0)"}})]),_vm._v(" "),_c(_setup.CdnImg,{ref:"image",staticClass:"image",attrs:{"src":_vm.data.image,"width":500,"height":500,"format":"webp","alt":_vm.data.title}})],1):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cn-category-banner-large__lines"},[_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }