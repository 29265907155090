import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ac0722a8 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _00443e26 = () => interopDefault(import('../pages/account-removal-request.vue' /* webpackChunkName: "pages/account-removal-request" */))
const _274c9a30 = () => interopDefault(import('../pages/certificates/index.vue' /* webpackChunkName: "pages/certificates/index" */))
const _53d3c9dc = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _806eb752 = () => interopDefault(import('../pages/drivers-and-manuals/index.vue' /* webpackChunkName: "pages/drivers-and-manuals/index" */))
const _396704e0 = () => interopDefault(import('../pages/e-catalogue.vue' /* webpackChunkName: "pages/e-catalogue" */))
const _03fb28f2 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _04a16f31 = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _1893be19 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3c958ddc = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _afea4d56 = () => interopDefault(import('../pages/search/drivers.vue' /* webpackChunkName: "pages/search/drivers" */))
const _8452dafa = () => interopDefault(import('../pages/search/faqs.vue' /* webpackChunkName: "pages/search/faqs" */))
const _4a940b8e = () => interopDefault(import('../pages/search/news.vue' /* webpackChunkName: "pages/search/news" */))
const _6c82b94a = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _9bb140d2 = () => interopDefault(import('../pages/user-help-desk/index.vue' /* webpackChunkName: "pages/user-help-desk/index" */))
const _bab7da64 = () => interopDefault(import('../pages/where-to-buy/index.vue' /* webpackChunkName: "pages/where-to-buy/index" */))
const _4fa42544 = () => interopDefault(import('../pages/user-help-desk/faq/index.vue' /* webpackChunkName: "pages/user-help-desk/faq/index" */))
const _6c976d1e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _531167d4 = () => interopDefault(import('../pages/user-help-desk/faq/_slug.vue' /* webpackChunkName: "pages/user-help-desk/faq/_slug" */))
const _2595f8e8 = () => interopDefault(import('../pages/certificates/_slug.vue' /* webpackChunkName: "pages/certificates/_slug" */))
const _07686b82 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _65c09144 = () => interopDefault(import('../pages/product-category/_category/index.vue' /* webpackChunkName: "pages/product-category/_category/index" */))
const _b2f9b7ee = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _02eacde9 = () => interopDefault(import('../pages/promo/_slug.vue' /* webpackChunkName: "pages/promo/_slug" */))
const _64630566 = () => interopDefault(import('../pages/product-category/_category/_subcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/index" */))
const _132554f4 = () => interopDefault(import('../pages/product-category/_category/_subcategory/_subsubcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/_subsubcategory/index" */))
const _74012e50 = () => interopDefault(import('../pages/product-category/_category/_subcategory/_subsubcategory/_subsubsubcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/_subsubcategory/_subsubsubcategory/index" */))
const _7004afae = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _ac0722a8,
    name: "about___bg"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___cs"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___en"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___hu"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___pl"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___ro"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___ru"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___sk"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___uk"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___es"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___de"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___en"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___en"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___en"
  }, {
    path: "/about",
    component: _ac0722a8,
    name: "about___el"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___bg"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___cs"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___en"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___hu"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___pl"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___ro"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___ru"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___sk"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___uk"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___es"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___de"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___en"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___en"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___en"
  }, {
    path: "/account-removal-request",
    component: _00443e26,
    name: "account-removal-request___el"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___bg"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___cs"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___hu"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___pl"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___ro"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___ru"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___sk"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___uk"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___es"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___de"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _274c9a30,
    name: "certificates___el"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___bg"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___cs"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___en"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___hu"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___pl"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___ro"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___ru"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___sk"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___uk"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___es"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___de"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___en"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___en"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___en"
  }, {
    path: "/contact-us",
    component: _53d3c9dc,
    name: "contact-us___el"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___bg"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___cs"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___hu"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___pl"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___ro"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___ru"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___sk"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___uk"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___es"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___de"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _806eb752,
    name: "drivers-and-manuals___el"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___bg"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___cs"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___en"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___hu"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___pl"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___ro"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___ru"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___sk"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___uk"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___es"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___de"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___en"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___en"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___en"
  }, {
    path: "/e-catalogue",
    component: _396704e0,
    name: "e-catalogue___el"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___bg"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___cs"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___en"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___hu"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___pl"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___ro"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___ru"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___sk"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___uk"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___es"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___de"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___en"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___en"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___en"
  }, {
    path: "/news",
    component: _03fb28f2,
    name: "news___el"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___bg"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___cs"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___en"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___hu"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___pl"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___ro"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___ru"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___sk"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___uk"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___es"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___de"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___en"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___en"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___en"
  }, {
    path: "/promo",
    component: _04a16f31,
    name: "promo___el"
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___bg"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___bg"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___bg"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___bg"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___bg"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___cs"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___cs"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___cs"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___cs"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___cs"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___en"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___en"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___en"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___en"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___en"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___hu"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___hu"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___hu"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___hu"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___hu"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___pl"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___pl"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___pl"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___pl"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___pl"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___ro"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___ro"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___ro"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___ro"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___ro"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___ru"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___ru"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___ru"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___ru"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___ru"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___sk"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___sk"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___sk"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___sk"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___sk"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___uk"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___uk"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___uk"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___uk"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___uk"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___es"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___es"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___es"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___es"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___es"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___de"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___de"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___de"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___de"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___de"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___en"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___en"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___en"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___en"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___en"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___en"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___en"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___en"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___en"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___en"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___en"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___en"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___en"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___en"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___en"
    }]
  }, {
    path: "/search",
    component: _1893be19,
    children: [{
      path: "",
      component: _3c958ddc,
      name: "search___el"
    }, {
      path: "drivers",
      component: _afea4d56,
      name: "search-drivers___el"
    }, {
      path: "faqs",
      component: _8452dafa,
      name: "search-faqs___el"
    }, {
      path: "news",
      component: _4a940b8e,
      name: "search-news___el"
    }, {
      path: "products",
      component: _6c82b94a,
      name: "search-products___el"
    }]
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___bg"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___cs"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___en"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___hu"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___pl"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___ro"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___ru"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___sk"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___uk"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___es"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___de"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___en"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___en"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___en"
  }, {
    path: "/user-help-desk",
    component: _9bb140d2,
    name: "user-help-desk___el"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___bg"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___cs"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___hu"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___pl"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___ro"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___ru"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___sk"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___uk"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___es"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___de"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _bab7da64,
    name: "where-to-buy___el"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___bg"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___cs"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___en"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___hu"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___pl"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___ro"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___ru"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___sk"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___uk"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___es"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___de"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___en"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___en"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___en"
  }, {
    path: "/user-help-desk/faq",
    component: _4fa42544,
    name: "user-help-desk-faq___el"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___bg"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___cs"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___en"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___hu"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___pl"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___ro"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___ru"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___sk"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___uk"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___es"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___de"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___en"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___en"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___en"
  }, {
    path: "/",
    component: _6c976d1e,
    name: "index___el"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___bg"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___cs"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___en"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___hu"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___pl"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___ro"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___ru"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___sk"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___uk"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___es"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___de"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___en"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___en"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___en"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _531167d4,
    name: "user-help-desk-faq-slug___el"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___bg"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___cs"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___hu"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___pl"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___ro"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___ru"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___sk"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___uk"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___es"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___de"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _2595f8e8,
    name: "certificates-slug___el"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___bg"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___cs"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___en"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___hu"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___pl"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___ro"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___ru"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___sk"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___uk"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___es"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___de"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___en"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___en"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___en"
  }, {
    path: "/news/:slug",
    component: _07686b82,
    name: "news-slug___el"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___bg"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___cs"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___en"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___hu"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___pl"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___ro"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___ru"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___sk"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___uk"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___es"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___de"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___en"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___en"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___en"
  }, {
    path: "/product-category/:category",
    component: _65c09144,
    name: "product-category-category___el"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___bg"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___cs"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___hu"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___pl"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___ro"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___ru"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___sk"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___uk"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___es"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___de"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _b2f9b7ee,
    name: "product-slug___el"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___bg"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___cs"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___en"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___hu"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___pl"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___ro"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___ru"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___sk"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___uk"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___es"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___de"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___en"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___en"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___en"
  }, {
    path: "/promo/:slug",
    component: _02eacde9,
    name: "promo-slug___el"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___hu"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___pl"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___ru"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___es"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___de"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _64630566,
    name: "product-category-category-subcategory___el"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___hu"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___pl"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___ru"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___es"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___de"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _132554f4,
    name: "product-category-category-subcategory-subsubcategory___el"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___hu"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___pl"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___ru"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___es"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___de"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _74012e50,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___el"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___bg"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___cs"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___hu"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___pl"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___ro"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___ru"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___sk"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___uk"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___es"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___de"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _7004afae,
    name: "slug___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
