/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { useStore, useContext, computed } from '@nuxtjs/composition-api';

const loadLanguagesFromConfig = () => {
  const store = useStore();
  const { i18n } = useContext();
  const domains = store.getters['config/getDomains'];
  const languages = computed(() =>
    domains
      .map((domain) => {
        return {
          domain: `${domain.protocol}://${domain.domain}`,
          ...domain.language.find((lang) => lang.default === true),
        };
      })
      .sort(
        (a, b) =>
          (i18n.defaultLocale === b.code) - (i18n.defaultLocale === a.code),
      ),
  );
  return languages;
};

const useLanguages = () => {
  const { i18n } = useContext();
  const store = useStore();
  const menu = store.getters['menus/getLanguageSelect'];
  const currentLocale = i18n.locale;

  const languages = computed(() => {
    return menu?.map((language) => ({
      name: language.name,
      code: language.badge_text,
      domain: language.url,
    }));
  });

  const currentLocaleName = computed(
    () =>
      languages.value?.find((language) => language.code === currentLocale)
        ?.name,
  );

  return {
    languages,
    currentLocale,
    currentLocaleName,
  };
};

export default useLanguages;
