import { useContext, ref } from '@nuxtjs/composition-api';
import { Country, Ids } from '@webplatform/nuxt-cms-js-sdk';

export default () => {
  const {
    $api: { serviceCentersService, countriesService },
  } = useContext();
  const countries = ref<Country[]>([]);
  const loading = ref(false);

  const fetchCountries = async () => {
    try {
      loading.value = true;
      const { data: servicesData } =
        await serviceCentersService.getServicesCountriesIds({});
      if (servicesData.length) {
        const { data: countriesData } =
          await countriesService.getCountriesByIds({
            country_id: servicesData.map((s: Ids) => s.id),
            limit: 1000,
          });
        countries.value = countriesData;
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };
  return {
    countries,
    fetchCountries,
  };
};
