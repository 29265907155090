export const strict = false;

export const actions = {
  async nuxtServerInit({ dispatch }, context) {
    const { $api } = context;
    await dispatch('categories/load', { $api });
    await dispatch('menus/load', { $api });
    await dispatch('config/load', { $api });
  },
};
