import { render, staticRenderFns } from "./AppSearch.vue?vue&type=template&id=32b98a9f"
import script from "./AppSearch.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppSearch.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppSearch.vue?vue&type=style&index=0&id=32b98a9f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSearchForm: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod/components/ui/UiSearchForm.vue').default})
